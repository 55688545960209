import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const ZhartivlyviPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "zhartivlyvi-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Жартівливі пісні"
        description="Жартівливі пісні — жанр українського музичного фольклору, що характеризується жартівливим чи сатиричним змістом."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Жартівливі пісні
          </h1>
          <p className="typo-body mt-4 max-w-xl">
            Жартівливі пісні — жанр українського музичного фольклору, що характеризується жартівливим чи сатиричним змістом.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список жартівливих пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру жартівливої пісні
        </h2>
        <p className="typo-body mt-4">
          Жартівливі пісні — численні за кількістю і розмаїті за тематикою — становлять значний розділ української
          народної пісенності, це здавна було відзначено фольклористами. Ще в середині минулого сторіччя М. Закревський
          підкреслив, що в цих піснях бачимо «привілля для веселощів, іронії, дотепу українця, без яких він не може
          жити. Ця веселість тим привабливіша, що вона непідробна, природна; а дотепність, неначе іскра, часто
          з'являється несподіва­но і з блиском».
        </p>
        <p className="typo-body mt-4">
          На відміну від ліричних пісень про кохання та родинне життя, які можуть виконуватися і в гурті й на самоті,
          жартівливі потребують аудиторії, бо розраховані на співучасть, на те, щоб дотеп, каламбур, жарт був почутий,
          розрадив і розвеселив слухачів. Жартівливі пісні часто виконуються як приспівки до танцю.
        </p>
        <p className="typo-body mt-4">
          Від добродушного гумору до в'їдливого глузування - такий широкий настроєний діапазон жартівливих пісень.
          Іронія просвічує у самохарактеристиках: "хоч я гарна, та ледащо...", "чи я собі не хазяйка та не господиня -
          три дні хати не мела, сміття по коліна"; в жартівливих піснях постійно звучить протиставлення
        </p>
        <p className="typo-body italic mt-4">
          бідних і багатих
          <br />
          щедрих і скупих
          <br />
          роботящих і ледарів
          <br />
          скромних і хвалькуватих
          <br />
          примітних і незугарних
          <br />
          кмітливих і недотеп
          <br />
          красивих і поганих на вроду,
        </p>
        <p className="typo-body mt-4">
          причому виразна перевага надається першим. Так створюється колективна думка про народну мораль, етику й
          естетику. Сміху бояться навіть ті, хто нічого не боїться. Хоч сміх не відбирає ні життя, ні маєтку, одначе
          винний почуває себе перед громадським сміхом, за влучним висловом М. Гоголя, "як зв'язаний заєць". Хіба не
          таким "зайцем" почуває себе жених-невдаха з відомої пісні, який боїться батька, матері, собаки і навіть мишей
          у хаті своєї дівчини, через що й одержує одкоша, або роззявляка, який "коня глядав - на нім був, коня глядав
          сивого аж до рана білого". Іронією сповнені пісні про "несамокритичних" дідів, які, уповаючи на своє багатство,
          сватаються до бідних дівчат, так само висміюються й багачки, які "сидять дома з кіньми та волами", а вбогі
          йдуть заміж "з чорними бровами". У жартівливих піснях показано смішні сторони родинних взаємин тещі й зятя,
          свекрухи й невістки, чоловіка й жінки, кумів тощо.
        </p>
        <p className="typo-body mt-4">
          Найдавніші записи українських жартівливих пісень знаходимо в польських та українських співанках XVII ст.,
          чимало записаних тоді пісень дожило до наших днів. Серед них "Чом, чом, чому босо ходиш", "Ой гиля, гиля,
          гусоньки, на став", "Ой не стій під вікном", "Та орав мужик край дороги", "Перестань, перестань до мене ходити"
          та ін. Пісні "Ой під вишнею, під черешнею", що звучить у п'єсі І. Котляревського "Наталка Полтавка", відома
          ще з дописів XVIII ст.
        </p>
        <p className="typo-body mt-4">
          Публікація жартівливих пісень (у зб. М. Максимовича, Б. Залєського, Жеготи Паулі) починається в першій
          половині XIX ст. Жартівливі пісні, - органічний складник української драматургії - комедій та водевілів, -
          були і залишаються дійовою виховною силою, висміюючи все, з чим не мириться народне уявлення про людську
          поведінку, і тим самим утверджуючи здорові морально-етичні й естетичні погляди.
        </p>
      </div>
    </>
  );
};

export default ZhartivlyviPisniPage;
